var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v(_vm._s(_vm.newItemLabel))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('Input',{attrs:{"label":"اسم المنطقة","type":"text","model":_vm.form.name,"errorMessages":_vm.nameErrors},on:{"changeValue":function (val) {
              _vm.form.name = val;
              _vm.$v.form.name.$touch();
            }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('Input',{attrs:{"label":"الحالة","type":"text","placeholder":'0/1',"model":_vm.form.status,"errorMessages":_vm.statusErrors},on:{"changeValue":function (val) {
                _vm.form.status = val;
                _vm.$v.form.status.$touch();
              }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"100","md":"15"}},[_c('gmap-map',{staticStyle:{"width":"100%","height":"400px"},attrs:{"center":_vm.center,"zoom":_vm.zoom},on:{"click":_vm.onMapClick}},[_c('gmap-polyline',{attrs:{"path":_vm.form.markers,"options":{
                  strokeColor:  _vm.form.color ? '#' + _vm.form.color : null, strokeWeight: 6
                }}}),_vm._l((_vm.form.markers),function(marker,index){return _c('gmap-marker',{key:'marker-' + index,attrs:{"position":marker,"clickable":true},on:{"click":_vm.onMapClick}})}),_vm._l((_vm.form.allZonesMarkers),function(markers,index){return _c('gmap-marker',{key:'allZonesMarker-' + index},_vm._l((markers),function(position,positionIndex){return _c('gmap-marker',{key:'position-' + positionIndex,attrs:{"position":position}})}),1)}),_vm._l((_vm.form.allZonesMarkers),function(markers,index){return _c('gmap-polyline',{key:'polyline-' + index,attrs:{"path":markers,"options":{ strokeColor: _vm.form.color ? '#' + _vm.form.color : null, strokeOpacity: 0.7, strokeWeight: 1.7 }}})})],2)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('Input',{attrs:{"label":"الإحداثيات","type":"text","placeholder":'[[33.492..,36.241...]]',"model":_vm.form.coordinates,"value":_vm.form.coordinates,"errorMessages":_vm.coordinatesErrors},on:{"changeValue":function (val) {
                _vm.form.coordinates = val;
                _vm.$v.form.coordinates.$touch();
              }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('Input',{attrs:{"label":"اللون","type":"text","placeholder":'ff0ff',"model":_vm.form.color,"errorMessages":_vm.colorErrors},on:{"changeValue":function (val) {
                _vm.form.color = val;
                _vm.$v.form.color.$touch();
              }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('div',[_c('v-select',{attrs:{"items":_vm.locationOptions,"item-text":"location_full_name","item-value":"location_id","label":"اختر المنطقة المطلوبة","errorMessages":_vm.location_idErrors},on:{"select":function (val) {
                  _vm.form.location_id = val.value;
                  _vm.$v.form.location_id.$touch();
                }},model:{value:(_vm.form.location_id),callback:function ($$v) {_vm.$set(_vm.form, "location_id", $$v)},expression:"form.location_id"}})],1)])],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('div',{staticStyle:{"margin-left":"10px","margin-bottom":"9px"}},[_c('v-btn',{staticStyle:{"color":"white"},attrs:{"color":"red darken-1"},on:{"click":_vm.clearCoordinates}},[_vm._v("مسح الإحداثيات ")])],1),_c('div',[_c('Button',{attrs:{"color":"blue darken-1","type":"submit","label":"حفظ"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }